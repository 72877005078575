export const form = {
	id:undefined,
	unit:undefined,
	contact:undefined,
	tel:undefined,
	idType:undefined,
	idNumber:undefined,
	remarks:undefined,
	imgUrls:[],
}
//身份证校验
const handleConfirm = (rule,value,callback) => {
	if(!value){
		callback();
	  }else{
		// 正则判断失败抛出错误，否则直接callback()
		if(!/(^\d{18}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)){
		  callback(new Error("身份证格式不正确!"));
		}else{
		  callback();
		}
	}
}

export const rules = {
	unit:[{required:true,message:'请输入',trigger:'blur'}],
	contact:[{required:true,message:'请输入联系方式',trigger:'blur'}],
	tel:[{required:true,message:'请输入',trigger:'blur'}],
	idType:[{required:true,message:'请选择',trigger:'change'}],
	idNumber:[{required:true,message:'请输入证件号码',trigger:'blur'},{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}],
	remarks:[{required:true,message:'请输入备注',trigger:'blur'}],
}
export const options = {
	idType:[
		{ id:1, name:'身份证' },
		{ id:2, name:'营业执照' },
	],
	
}

