<template>
  <div>
    <div class="cardTitle">活动主办方</div>
    <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm>
    <a-button style="margin: 10px" class="add-btn" @click="drawer.show = true"
      >新增主办方</a-button
    >
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 1600 }"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="edit(row.id)">修改</a>
          <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
        </a-space>
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="del"> 批量删除 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <addForm
      :show="drawer.show"
      @success="success"
      @addClose="addClose"
      :editId="editId"
    ></addForm>
  </div>
</template>

<script>
import { organizerList, organizerDel } from "@/api/operation/activity/organizer";
import { formItem, columns, pagination } from "./depend/config";
import addForm from "./depend/form.vue";
export default {
	components: {
		addForm,
	},
	data() {
		return {
			drawer: {
				show: false,
			},
			editId: null,
			tableData: [],
			searchForm: {
				unit: undefined,
				contact: undefined,
				tel: undefined,
				holdNumType: undefined,
				holdNum: undefined,
				createStartDate: undefined,
				createEndDate: undefined,
			},
			formItem,
			columns,
			pagination,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		search(data){
			this.searchForm = data;
			this.getData()
		},
		async getData() {
			let res = await organizerList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys=[]
		},
		edit(id) {
			this.editId = id;
			this.drawer.show = true;
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async () => {
					let res = await organizerDel({ activityOrganizerIds: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.selectedRowKeys);
			}
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		addClose() {
			this.drawer.show = false;
			this.editId = null;
		},
		success() {
			this.getData();
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
</style>
