<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-row>
			<a-col :span="12">
              <a-form-model-item prop="unit" label="主办方单位">
                <a-input
                  v-model="form.unit"
                  placeholder="请输入主办方名称"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
			<a-col :span="12">
              <a-form-model-item prop="contact" label="联系人">
                <a-input
                  v-model="form.contact"
                  placeholder="请输入联系人"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
			<a-col :span="12">
              <a-form-model-item prop="tel" label="联系方式">
                <a-input
                  v-model="form.tel"
                  placeholder="请输入联系方式"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
			<a-col :span="12">
              <a-form-model-item prop="idType" label="证件类型">
                <a-select
                  v-model="form.idType"
                  placeholder="请选择"
                  style="width: 80%"
                >
					<a-select-option v-for="item in options.idType" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
				</a-select>
              </a-form-model-item>
            </a-col>
			<a-col :span="12">
              <a-form-model-item prop="idNumber" label="证件号码">
                <a-input
                  v-model="form.idNumber"
                  placeholder="请输入证件号码"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
			<a-col :span="24">
              <a-form-model-item prop="remarks" label="备注">
                <a-textarea
                  v-model="form.remarks"
                  placeholder="请输入备注"
                  style="width: 80%"
                ></a-textarea>
              </a-form-model-item>
            </a-col>
			<a-col :span="24">
				主办方图片
				<commonUpload :fileList='fileList' @handleChange='handleChange'></commonUpload>
			</a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import {
	organizerInsert,
	organizerUpdate,
	organizerInfo,
} from "@/api/operation/activity/organizer";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "新增主办方",
			form,
			rules,
			options,
			fileList: [],
		};
	},
	methods: {
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.fileList = [];
			this.form.imgUrls = [];
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editId === null) {
						let res = await organizerInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await organizerUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
		// handleChange(imgDataList) {
		// 	this.fileList = imgDataList
		// 	let arr = []
		// 	imgDataList.forEach(ele => {
		// 		arr.push(ele.response.data)
		// 	})
		// 	this.form.imgUrls = arr
		// },
		handleChange(data) {
			this.fileList = data;
			this.form.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgUrls.push(ele.response.data)
				}
			})
		},
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.title = "修改主办方";
					this.form.id = val;
					organizerInfo({ activityOrganizerId: val }).then((res) => {
						this.form = res.data;
						this.form.imgUrls = [];
						if (res.data.imgList.length > 0) {
							const pic = [];
							this.form.imgUrls.push(res.data.imgList[0].url)
							for (let item of res.data.imgList) {
								let obj = {
									name: item.url.split("_")[0] + "." + item.url.split(".")[1],
									url: this.$ImgUrl(item.url),
									uid: item.url.split("_")[1],
									status: "done",
									thumbUrl: this.$ImgUrl(item.url),
								};
								pic.push(obj);
							}
							this.fileList = pic;
						}else{
							this.form.imgUrls=[]
						}
					});
				} else {
					this.title = "新增主办方";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
