export const formItem = [
	{
		type: 'input',
		label:'单位名称',
		prop:'unit',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'联系人',
		prop:'contact',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'联系方式',
		prop:'tel',
		placeholder:'请输入'
	},
	{
		type: 'select',
		label:'举办次数类型',
		prop:'holdNumType',
		option:[{ id:1,name:'大于且包括'},{ id:2,name:'等于'},{ id:3,name:'小于且包括'}],
		placeholder:'请选择'
	},
	{
		type: 'input',
		label:'举办次数',
		prop:'holdNum',
		placeholder:'请输入'
	},
	{
		type: 'time',
		label:'创建时间',
		start: 'createStartDate',
		end:'createEndDate'
	},
]
export const columns = [
	{
		title: "主办方单位",
		dataIndex: "unit",
		width: "16%",
	},
	{
		title: "联系人",
		dataIndex: "contact",
		width: "12%",
	},
	{
		title: "联系方式",
		dataIndex: "tel",
		width: "15%",
	},
	{
		title: "举办次数",
		dataIndex: "holdNum",
		width: "12%",
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
		width: "20%",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}