import httpService from "@/request"

// 主办方列表
export function organizerList(params) {
	return httpService({
		url: `/user/activityOrganizer/list`,
		method: 'get',
		params: params,
	})
}
export function organizerInfo(params) {
	return httpService({
		url: `/user/activityOrganizer/findById`,
		method: 'get',
		params: params,
	})
}
export function organizerInsert(params) {
	return httpService({
		url: `/user/activityOrganizer/insert`,
		method: 'post',
		data: params,
	})
}
export function organizerUpdate(params) {
	return httpService({
		url: `/user/activityOrganizer/update`,
		method: 'post',
		data: params,
	})
}
export function organizerDel(params) {
	return httpService({
		url: `/user/activityOrganizer/delete`,
		method: 'post',
		data: params,
	})
}
